/* eslint-disable indent */
export const types = {
  object: '[object Object]',
  array: '[object Array]',
  string: '[object String]',
  boolean: '[object Boolean]',
  number: '[object Number]',
};

export const getType = prop => Object.prototype.toString.call(prop);

export const isObject = maybeAnObject =>
  getType(maybeAnObject) === types.object;

export const unMask = value => value.replace(/\D+/g, '');

export const removeSpaces = stringValue => stringValue.replace(/\s/g, '');

export const cleanObject = objs => objs.map(obj => obj.value);

/**
 * @important
 * **Ignore javascript:S2245**, reasons stated below:
   "Make sure that using this pseudorandom number generator is safe here."
   1. Function isn't being used anywhere. It's useless to point errors if it's not in use.
   2. The function generates a hexadecimal string, hence the unneeded weak crypto check.
 */
export const randColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`; //NOSONAR

export const promisify = func => (...args) =>
  new Promise((resolve, reject) =>
    func(...args, (err, result) => (err ? reject(err) : resolve(result)))
  );

export const capitalizeString = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const suffixes = ['REQUEST', 'FULFILLED', 'REJECTED'];

export const getTypes = type =>
  suffixes.reduce(
    (acc, curr) => ({ ...acc, [`${type}_${curr}`]: `${type}_${curr}` }),
    {}
  );

export const isEmptyObj = (obj = {}) =>
  Object.values(obj).filter(Boolean).length === 0;

export const mapDispatchToProps = actions => dispatch => ({
  actions: Object.entries(actions).reduce(
    (acc, [actionName, action]) => ({
      ...acc,
      [actionName]: (...args) => dispatch(action(...args)),
    }),
    {}
  ),
});

function* getColor(colors, reset = 0) {
  let index = reset;
  while (true) {
    yield colors[index];
    index = (index + 1) % colors.length;
  }
}
export const colorGen = (colors = []) => getColor(colors);

export const isRequestOK = status =>
  [200, 201, 202, 204, 206, 208, 300].includes(status);

export const formValidation = {
  isRequired: (field, value) => !value && { [field]: 'campo obrigatório' },
  length: (field, value, length, message) =>
    value.length !== length && {
      [field]: message || `${field} deve conter ${length} caracteres`,
    },
};

export const replaceAllButDigits = (stringValue = '') =>
  stringValue.replace(/[^0-9.]/g, '');
export const replaceAllButDigitsWithoutPoint = (stringValue = '') =>
  stringValue.replace(/\D/g, '');

export const alphabeticalSortByProp = prop => (a, b) => {
  if (a[prop] < b[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  return 0;
};

export const flattenObject = (myObk, context = '') =>
  Object.keys(myObk).reduce(
    (acc, current) =>
      isObject(myObk[current])
        ? { ...acc, ...flattenObject(myObk[current], current) }
        : {
            ...acc,
            [context ? `${context}.${current}` : current]: myObk[current],
          },
    {}
  );

export const alphabeticalSortArray = array =>
  array.sort((a, b) => {
    return a.firstname.localeCompare(b.firstname);
  });

export const filterNonEmptyKeys = obj =>
  Object.keys(obj).reduce(
    (acc, current) =>
      obj[current] ? { ...acc } : { ...acc, [current]: obj[current] },
    {}
  );

const filterObjectHelper = obj => Object.keys(obj).filter(key => !obj[key]);

export const filterObject = (obj, msg = '') => {
  const filteredObjectedKeys = filterObjectHelper(obj);
  return filteredObjectedKeys.reduce(
    (acc, current) => ({ ...acc, [current]: msg }),
    {}
  );
};

export const filterObjectByKey = (obj, key) =>
  Object.keys(obj).reduce(
    (acc, current) =>
      current === key ? { ...acc } : { ...acc, [current]: obj[current] },
    {}
  );

export const isANumberValue = value => /^\d*$/.test(value);

export const objHasProp = (obj, prop) =>
  obj && Object.prototype.hasOwnProperty.call(obj, prop);

export const findValueByPropName = (array, prop, name) =>
  array.find(item => item.name === name)[prop];

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};
export const formatCurrency = number =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    number
  );

export const formatCNPJ = cnpj => {
  if (cnpj) {
    const string = cnpj.toString();

    return `${string.substr(0, 2)}.${string.substr(2, 3)}.${string.substr(
      5,
      3
    )}/${string.substr(8, 4)}-${string.substr(12, 2)}`;
  }
};

export const formatCPF = cnpj => {
  const string = cnpj.toString();

  return `${string.substr(0, 3)}.${string.substr(2, 3)}.${string.substr(
    5,
    3
  )}-${string.substr(8, 2)}`;
};

export const validateCpf = cpfString => {
  // Verifica se o CPF é uma string válida ou está vazio
  if (!cpfString || typeof cpfString !== 'string') {
    return false;
  }

  // Remove caracteres não numéricos do CPF
  const cpf = cpfString.replace(/\D/g, '');

  // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida de números
  if (cpf.length !== 11 || /^(.)\1{10}$/.test(cpf)) {
    return false;
  }

  // Função auxiliar para calcular o dígito de verificação
  const calculateDigit = slice => {
    // Soma ponderada dos dígitos para cálculo do dígito
    const sum = Array(slice).reduce(
      (acc, digit, index) => acc + digit * (slice.length + 1 - index),
      0
    );

    // Calcula o dígito de verificação e o retorna como string
    return (sum % 11 < 2 ? 0 : 11 - (sum % 11)).toString();
  };

  // Separa os dois últimos dígitos do CPF (dígitos de verificação)
  const digits = cpf.slice(-2).split('');

  // Calcula o primeiro dígito de verificação
  const firstDigit = calculateDigit(cpf.slice(0, 9).split(''));

  // Calcula o segundo dígito de verificação
  const secondDigit = calculateDigit(cpf.slice(0, 10).split(''));

  // Verifica se os dígitos de verificação calculados são iguais aos dígitos informados no CPF
  return digits[0] === firstDigit && digits[1] === secondDigit;
};

export const isValidJSON = str => {
  const realStr = typeof str === 'object' ? JSON.stringify(str) : str;

  try {
    JSON.parse(realStr);
  } catch (e) {
    return false;
  }
  return true;
};

// Based on public https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
// Used for generating a unique value for components, based on response data.
/* Quote: "A fast and simple 53-bit string hash function with decent collision resistance.
Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity." */

/* eslint-disable no-bitwise */
export const cyrb53Hash = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
/* eslint-disable no-bitwise */

export const defaultLinks = {
  'Política de Privacidade': {
    url: 'https://skyone.solutions/juridico/politica-de-privacidade/',
    name: 'Política de Privacidade',
    external: true,
  },
  'Privacy Policy': {
    url: 'https://skyone.solutions/en/legal/privacy-policy/',
    name: 'Privacy Policy',
    external: true,
  },
  'Política de Privacidad': {
    url: 'https://skyone.solutions/es/legal/politica-de-privacidad/',
    name: 'Política de Privacidad',
    external: true,
  },
  'Termos e Condições': {
    url: 'https://skyone.solutions/juridico/codigo-de-etica-e-conduta/',
    name: 'Termos e Condições',
    external: true,
  },
  'Terms and Conditions': {
    url: 'https://skyone.solutions/en/legal/code-of-ethics-and-conduct/',
    name: 'Terms and Conditions',
    external: true,
  },
  'Términos y Condiciones': {
    url: 'https://skyone.solutions/es/legal-y-grc/codigo-de-etica-y-conducta/',
    name: 'Términos y Condiciones',
    external: true,
  },
};
