/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export default ({ palette }: Theme) =>
  createStyles({
    container: {
      padding: '0 40px',
    },
    titleContainer: {
      marginTop: '50px',
      marginBottom: '10px',
    },
    Title: {
      color: 'black',
      fontSize: 22,
      fontFamily: 'Roboto',
      fontWeight: 900,
      textTransform: 'uppercase',
      marginRight: '14px',
    },
    subTitle: {
      color: palette.primary.main,
      fontSize: 22,
      fontFamily: 'Roboto',
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    text: {
      fontFamily: 'Roboto',
      color: 'white',
      padding: 15,
      fontSize: 14,
    },
    icon: {
      marginTop: 25,
      color: '#F0f0f0',
    },
  });
