enum Types {
  CLIENTS_REQUEST = 'CLIENTS_REQUEST',
  CLIENTS_FULFILLED = 'CLIENTS_FULFILLED',
  CLIENTS_REJECTED = 'CLIENTS_REJECTED',

  CLIENT_SELECTED = 'CLIENT_SELECTED',
  CLIENT_SELECTED_FULFILLED = 'CLIENT_SELECTED_FULFILLED',
  CLIENT_SELECTED_REJECTED = 'CLIENT_SELECTED_REJECTED',

  CLIENT_LOGIN_ATTEMPT_REQUEST = 'CLIENT_LOGIN_ATTEMPT_REQUEST',
  CLIENT_LOGIN_ATTEMPT_FULFILLED = 'CLIENT_LOGIN_ATTEMPT_FULFILLED',
  CLIENT_LOGIN_ATTEMPT_REJECTED = 'CLIENT_LOGIN_ATTEMPT_REJECTED',

  CLIENT_SESSION_REQUEST = 'CLIENT_SESSION_REQUEST',
  CLIENT_SESSION_PUT = 'CLIENT_SESSION_REQUEST',

  CLIENT_SET_DATA = 'CLIENT_SET_DATA',
  CLIENT_SET_LOADING = 'CLIENT_SET_LOADING',
  CLIENTS_RESET_STATE = 'CLIENTS_RESET_STATE',

  REGISTER_SELECTED_VIRTUALIZER_REQUEST = 'REGISTER_SELECTED_VIRTUALIZER_REQUEST',
  REGISTER_SELECTED_VIRTUALIZER_FULFILLED = 'REGISTER_SELECTED_VIRTUALIZER_FULFILLED',
  REGISTER_SELECTED_VIRTUALIZER_REJECTED = 'REGISTER_SELECTED_VIRTUALIZER_REJECTED',

  REGISTER_SELECTED_VIRTUALIZER_REQUEST_COMPLETED = 'REGISTER_SELECTED_VIRTUALIZER_REQUEST_COMPLETED',
  REGISTER_SELECTED_VIRTUALIZER_LINUX_REQUEST = 'REGISTER_SELECTED_VIRTUALIZER_LINUX_REQUEST',

  SET_IFRAME = 'SET_IFRAME',
}

export default Types;
