/* eslint-disable @typescript-eslint/camelcase */
import Apis from 'apis';

export const api = Apis({
  baseURL: process.env.REACT_APP_URL || '',
});

export default {
  selectClients: () => `/select_client`,
  clientSelected: client_uuid => `client_selected?cli_uuid=${client_uuid}`,
  loginAttempt: client_uuid => `login_attempt?cli_uuid=${client_uuid}`,
  callPlugin: client_uuid => `/call_plugin?cli_uuid=${client_uuid}`,
  userSession: client_uuid => `/user/session?cli_uuid=${client_uuid}`,
  validateSession: (isHttps, wn, instance, port) =>
    `${isHttps}://${instance}:${port}/software/validate_session.html?wn=${wn}&instance=${instance}&port=${port}`,
  registerSelectedVirtualizer: () => `/register_selected_virtualizer`,
};
